<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';

interface Props {
  to?: string | RouteLocationRaw;
  icon?: string | any;
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  isCenter?: boolean;
  isActive?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  to: '',
  icon: undefined,
  label: undefined,
  disabled: false,
  loading: false,
  isCenter: false,
  isActive: false,
});

const { icon, label, disabled, loading, isCenter, isActive } = toRefs(props);

const emit = defineEmits(['click']);

const handleClick = (event: MouseEvent) => {
  if (!props.loading && !props.disabled) {
    emit('click', event);
  }
};

const classes = computed(() => [
  {
    '-disabled': disabled.value,
    '-loading': loading.value,
    '-centered': isCenter.value,
    '-active': isActive.value,
  },
]);
</script>

<template>
  <button
    type="button"
    class="a-nav-bar-button"
    :class="classes"
    :disabled="loading || disabled"
    @click="handleClick"
  >
    <component :is="icon" v-if="icon" class="button-icon" />
    <span v-if="label" class="text-tiny">{{ label }}</span>
  </button>
</template>

<style lang="scss">
.a-nav-bar-button {
  width: 54px;
  height: 54px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  color: $text-secondary;
  align-items: center;
  background: transparent;
  transition: 0.25s ease-in-out;
  &:hover {
    color: $text-secondary;
  }
  &.-active {
    color: $text-accent;
  }
  &.-centered {
    background: $button-bg-primary;
    color: $button-text-primary;
    border-radius: 16px;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  &.-loading,
  &.-disabled {
    cursor: not-allowed;
    &.-centered {
      background-color: $button-bg-primary-disabled;
      color: $button-text-primary-disabled;
    }
  }
  .text-tiny {
    margin-top: 2px;
  }
}
</style>
